import { SideBarItem } from "../../../interfaces/SideBarItem";
import ImageIcon from "@material-ui/icons/Image";
import AddPlayerSponsor from "./AddPlayerSponsor";

const PlayerSponsors: SideBarItem = {
	name: "Player Sponsor Images",
	adminPanelURL: "playerSponsors",
	icon: <ImageIcon />,
	customList: true,
	customComponent: <AddPlayerSponsor />,
};

export default PlayerSponsors;
