import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import { apiDelete } from "../../dataProvider";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router";

interface IPlayerSponsorshipProps {
	playerSponsors: ISponsor[];
	textInputStyles: any;
}

interface ISponsor {
	id: string;
	sponsorName: string;
	imageUrl: string;
	playerID: string;
	clickUrl: string;
}

const PlayerSponsorship: FC<IPlayerSponsorshipProps> = ({ playerSponsors, textInputStyles }) => {
	const history = useHistory();

	async function deletePlayerSponsor(id: string): Promise<void> {
		const res = await apiDelete(`${process.env.REACT_APP_API_URL}/playerSponsors/${id}`);
		if (res.status === 202) {
			return window.location.reload();
		} else {
			return alert("Failed to Delete Sponsorship");
		}
	}

	function handleAddPlayerSponsor() {
		history.push("/admin/playerSponsors");
		window.location.reload();
	}

	return (
		<>
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<h2>Player Sponsorships:</h2>
				<Button
					aria-label="add"
					variant="contained"
					startIcon={<AddIcon />}
					style={{ float: "right", boxShadow: "none", marginBottom: "10px", height: "35px" }}
					onClick={() => handleAddPlayerSponsor()}
				>
					Add
				</Button>
			</div>

			{playerSponsors.length > 0 ? (
				<>
					{playerSponsors.map((sponsor, index) => (
						<div key={index}>
							<TextField
								value={sponsor.sponsorName}
								label="Sponsor Name"
								variant="filled"
								className={textInputStyles}
								InputLabelProps={{
									shrink: true,
								}}
								disabled={true}
							/>
							<TextField
								value={sponsor.clickUrl}
								label="Click URL"
								variant="filled"
								className={textInputStyles}
								InputLabelProps={{
									shrink: true,
								}}
								disabled={true}
							/>
							<TextField
								value={sponsor.imageUrl}
								label="Image"
								variant="filled"
								className={textInputStyles}
								InputLabelProps={{
									shrink: true,
								}}
								disabled={true}
							/>

							<div
								style={{
									width: "200px",
									height: "auto",
									marginBottom: "10px",
									backgroundColor: "#F6F6F6",
								}}
							>
								<img
									style={{
										width: "100%",
										height: "auto",
									}}
									src={sponsor.imageUrl}
									alt="Sponsorship"
								/>
							</div>
							<div>
								<Button
									aria-label="delete"
									variant="contained"
									startIcon={<DeleteIcon />}
									style={{ boxShadow: "none", marginTop: "20px", color: "red", marginBottom: "20px" }}
									onClick={() => deletePlayerSponsor(sponsor.id)}
								>
									Delete
								</Button>
							</div>
							<div style={{ width: "100%" }}>
								<hr />
							</div>
						</div>
					))}
				</>
			) : (
				<div style={{ marginBottom: "20px" }}>
					<b>None</b>
				</div>
			)}
		</>
	);
};

export default PlayerSponsorship;
