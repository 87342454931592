import { SideBarItem } from "../../../interfaces/SideBarItem";
import TvIcon from "@material-ui/icons/Tv";

const Channels: SideBarItem = {
	name: "Channels",
	icon: <TvIcon />,
	adminPanelURL: "channels",
	fetchAllURL: "channels/all",
	fetchSingleURL: "channels/:id",
	updateURL: "channels/:id",
	deleteURL: "channels/:id",
	postURL: "channels",
	columns: [
		{ id: "name", label: "Channel Name", type: "Text", isHeadCell: true, required: true },
		{ id: "image", label: "Image", type: "Image", isHeadCell: false, required: false },
	],
	orderBy: "name",
	order: "asc",
};

export default Channels;
