import { SideBarItem } from "../../../interfaces/SideBarItem";
import EventIcon from "@material-ui/icons/Event";

const Fixtures: SideBarItem = {
	name: "Fixtures",
	icon: <EventIcon />,
	adminPanelURL: "fixtures",
	fetchAllURL: "fixtures/all",
	fetchSingleURL: "fixtures/:id",
	updateURL: "fixtures/:id",
	deleteURL: "fixtures/:id",
	postURL: "fixtures",
	search: true,
	columns: [
		{ id: "roundNumber", label: "Round Number", type: "Number", isHeadCell: false, required: true },
		{ id: "roundString", label: "Round Label", type: "Text", isHeadCell: true, required: true },
		{
			id: "homeTeamID",
			label: "Home",
			type: "ReferenceInput",
			isHeadCell: true,
			required: true,
			referenceInputData: { keyInFetchResponse: "homeTeam", referenceURL: "teams/all", referenceID: "id", referenceName: "name" },
		},
		{
			id: "awayTeamID",
			label: "Away",
			type: "ReferenceInput",
			isHeadCell: true,
			required: true,
			referenceInputData: { keyInFetchResponse: "awayTeam", referenceURL: "teams/all", referenceID: "id", referenceName: "name" },
		},
		{ id: "homeScore", label: "Home Score", type: "Number", isHeadCell: true, required: false },
		{ id: "awayScore", label: "Away Score", type: "Number", isHeadCell: true, required: false },
		{ id: "start", label: "Start Time", type: "Timestamp", isHeadCell: true, required: true },
		{
			id: "venueID",
			label: "Venue",
			type: "ReferenceInput",
			isHeadCell: true,
			required: true,
			referenceInputData: { keyInFetchResponse: "venue", referenceURL: "venues/all", referenceID: "id", referenceName: "name" },
		},
		{
			id: "channels",
			label: "Channels",
			type: "ManyToMany",
			isHeadCell: true,
			required: true,
			referenceInputData: { keyInFetchResponse: "fixture_channels", referenceURL: "channels/all", referenceID: "id", referenceName: "name" },
		},
	],
	orderBy: "start",
	order: "asc",
};

export default Fixtures;
