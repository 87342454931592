import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Toolbar, createStyles, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { get, post } from "../../../dataProvider";
import React, { FC, useState, useEffect } from "react";
import ImageUploader from "../../sub-components/ImageUploader";
import SaveIcon from "@material-ui/icons/Save";
import LoadingSpinner from "../../sub-components/LoadingSpinner";
import { useHistory } from "react-router";

type SelectableOption = {
	id: string;
	name: string;
};

const useGetPlayers = () => {
	const [loading, setLoading] = useState(true);
	const [players, setPlayers] = useState<SelectableOption[]>([]);

	const fetchPlayers = async () => {
		setLoading(true);

		const data = await get(`${process.env.REACT_APP_API_URL}/players/all`);
		const playerOptions = data.data.map((player: { id: string; fullName: string }) => {
			return {
				id: player.id,
				name: player.fullName,
			};
		});

		setPlayers(playerOptions);
		setLoading(false);
	};

	useEffect(() => {
		fetchPlayers();
	}, []);

	return { players, loading };
};

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			width: "100%",
			marginLeft: "20px",
		},
		rootMobile: {
			width: "100%",
		},
		paper: {
			paddingLeft: "20px",
			height: "100%",
		},
		inputBox: {
			borderRadius: "5px 5px 0px 0px",
			width: "100%",
			marginBottom: "10px",
		},
		textField: {
			marginBottom: "20px",
			marginRight: "20px",
		},
	})
);

const AddPlayerSponsor: FC = () => {
	const history = useHistory();
	const isMobile = window.screen.width < 700;
	const classes = useStyles();
	const { players, loading } = useGetPlayers();

	const [playerId, setPlayerId] = useState("");
	const [sponsorName, setSponsorName] = useState("");
	const [clickUrl, setClickUrl] = useState("");
	const [imageUrl, setImageUrl] = useState("");

	async function savePlayerSponsor(): Promise<void> {
		const data: Record<string, string> = {
			playerId,
			sponsorName,
			imageUrl,
			clickUrl,
		};

		const required = ["playerId", "sponsorName", "imageUrl", "clickUrl"];

		for (const key of required) {
			if (!data[key]) {
				return alert(`Please Provide a ${key}`);
			}
		}

		const res = await post(`${process.env.REACT_APP_API_URL}/playerSponsors`, data);

		if (res.status === 200) {
			history.push(`/admin/players/edit/${data.playerId}`);
			window.location.reload();
		} else {
			alert("Failed to Create Player Sponsor");
		}
	}

	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<div className={!isMobile ? classes.root : classes.rootMobile}>
			<Paper className={classes.paper}>
				<Toolbar style={{ padding: "0px 12px" }}>
					<Typography style={{ width: "100%" }} variant="h6" id="tableTitle" component="div">
						Player Sponsor Images
					</Typography>
				</Toolbar>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<FormControl className={classes.inputBox}>
							<InputLabel id={"playerId-label"}>{"Player"}</InputLabel>
							<Select
								labelId={"playerId-label"}
								value={playerId || ""}
								onChange={(event: any) => {
									setPlayerId(event.target.value);
								}}
							>
								{players &&
									players.map((item) => (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
						{playerId && (
							<>
								<TextField
									value={sponsorName}
									label="Sponsor Name"
									variant="filled"
									className={classes.inputBox}
									onChange={(event) => setSponsorName(event.target.value)}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<TextField
									value={clickUrl}
									label="Click URL"
									variant="filled"
									className={classes.inputBox}
									onChange={(event) => setClickUrl(event.target.value)}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								{imageUrl && (
									<img
										style={{
											width: "100%",
											height: "auto",
										}}
										src={imageUrl || ""}
										alt="Img"
									/>
								)}
								<ImageUploader addUrlToState={(url) => setImageUrl(url)} />
							</>
						)}
					</Grid>
				</Grid>
				{playerId && (
					<Button
						aria-label="save"
						variant="contained"
						startIcon={<SaveIcon />}
						style={{ float: "left", boxShadow: "none", marginTop: "20px" }}
						onClick={() => savePlayerSponsor()}
					>
						Save
					</Button>
				)}
			</Paper>
		</div>
	);
};

export default AddPlayerSponsor;
